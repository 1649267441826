import isPropValid from '@emotion/is-prop-valid'
import Link from '../link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	isPropValid(prop) &&
	!['type', 'component', 'items', 'maxItemsForColumn', 'parentMaxItemsForColumn', 'key'].includes(prop)

const DropDownWrapper = styled('div')`
	padding: var(--spacing-1);
	display: block;
`

const NavLinkStyled = styled(Link)`
	color: inherit;
	cursor: pointer;
	display: inline-block;
	line-height: var(--size-18);
	margin-bottom: 26px;
	transition: color 0.2s;
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	font-family: var(--font-base-light);
	font-weight: 300;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	&:not(.no-link):hover {
		color: var(--color-grey30);
	}
	&:after {
		content: '';
		display: block;
		width: 0px;
		height: 1px;
		background-color: var(--color-primary);
		margin-top: -1px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}

	&.no-link {
		cursor: default;
	}
	&.item-view-all {
		font-family: var(--font-base-light);
		text-decoration: underline;
		font-weight: 300;
		margin-bottom: 0;
	}
`

const DropDrownList = styled(List, {
	shouldForwardProp,
})`
	position: revert;
	margin: 0 auto;
	display: grid;
	grid-template-columns: ${({ countItems }) => `repeat(${countItems}, 1fr)`};
	grid-template-rows: 1fr;
	grid-gap: 0;
	align-items: flex-start;
	padding: 0;
	width: 100%;
	background: var(--color-secondary);
	[class*='highlight'] {
		background: var(--color-grey0);
	}

	.DropDrownList-item {
		[class*='banner-hover-trigger'] {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			z-index: 1;
			align-items: flex-start;
			&._show {
				visibility: visible;
				opacity: 1;
			}
		}
		[class*='default-hover-trigger'] {
			opacity: 1;
			transition: all 0.2s ease-in-out;
			z-index: 1;
			&._hide {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
	&.parent_column-layout {
		grid-template-columns: repeat(1, minmax(25%, 1fr));
		grid-template-rows: repeat(${({ countItems }) => countItems ?? 1}, max-content);
		grid-auto-flow: column;
		justify-content: flex-start;
		padding: 0;

		.hasBanner {
			grid-column: ${({ countItems }) => (countItems >= 1 ? countItems + 1 : 1)};
			display: flex;
			justify-content: flex-end;
			grid-row-end: span ${({ countItems }) => (countItems >= 1 ? countItems + 1 : 1)};
			padding: 0;
		}
		.MuiListItem-root {
			padding: 0;
			&:not(.banner) {
				padding: 0 0 0 var(--spacing-3);
			}
			&:first-of-type:not(.banner) {
				padding-top: 24px;

				${({ theme }) => theme.breakpoints.up('xl')} {
					padding-top: 40px;
				}
			}
			&:nth-of-type(n
					+ ${({ parentMaxItemsForColumn }) =>
						parentMaxItemsForColumn >= 1 ? parentMaxItemsForColumn + 1 : -1}):not(.hasBanner) {
				margin-left: 50px;
				grid-column: 2;
			}
		}
		${NavLinkStyled} {
			font-family: var(--font-base-light) !important;
			font-weight: 300 !important;
		}
	}
`

const DropDrownListItem = styled(ListItem, {
	shouldForwardProp,
})`
	display: block;
	padding: 24px;
	height: 100%;
	transition: background var(--transition);

	${({ theme }) => theme.breakpoints.up('xl')} {
		padding: 40px 24px;
	}

	&.level-1 > .MuiLink-root:not(.item-view-all) {
		font-family: var(--font-base-medium);
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		font-weight: normal;
	}

	&.banner {
		padding: 0;
		display: flex;
		align-items: flex-start;
		grid-column-start: ${({ columnId }) => `${columnId}`};
		width: auto;
		> div {
			flex: 1;
		}
		&.two-columns {
			grid-column-end: span2;
		}
	}
	.MuiListItem-root {
		padding: 0;
		min-height: 0;
		height: auto;
		background: unset;
	}
	& .MuiList-root {
		display: block;
		padding: 0;
		background: unset;

		& .MuiListItem-root {
			padding-left: 0;
		}
	}
	&.menu-item-current {
		> a:not(.item-view-all):after {
			width: 100%;
		}
		&:hover {
			> a:not(.item-view-all):after {
				background-color: var(--color-grey30);
			}
		}
	}
`

const AdditionalBlock = styled('div')`
	width: 100%;
	padding: 12px 68px;
	text-align: center;
	border-top: 1px solid var(--color-grey40);
	& p {
		margin: 0;
	}
`
const ChildrenWrapper = styled('div')`
	display: flex;
	gap: 15px;
`

export { DropDownWrapper, DropDrownList, DropDrownListItem, NavLinkStyled, AdditionalBlock, ChildrenWrapper }
